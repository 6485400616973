import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "gatsby";
import SocialBar from "./SocialBar";
import { headShake } from "react-animations";
const fadeAnimation = keyframes`${headShake}`;

const NavBar = () => {
  const [menu, setMenuOpen] = useState(false);
  const handleMenu = () => {
    setMenuOpen(!menu);
  };

  return (
    <NavBarWrapper as="nav">
      <ul className={menu === true ? "nav-links open " : "nav-links"}>
        <li className="FistLi">
          <Link to="/">Acceuil</Link>
        </li>
        {/* <li>
          <Link to="/">Mes Créations</Link>
        </li> */}
        <li>
          <Link to="/Tarifs">Tarifs</Link>
        </li>
        <li>
          <Link to="/Contact">Contact</Link>
        </li>
      </ul>
      <SocialBar />
      <MenuBtn>
        <div className={`${menu ? "menu-btn open Link " : "menu-btn Link"}`}>
          <div className="menu-btn__burger" onClick={handleMenu}></div>
        </div>
      </MenuBtn>
    </NavBarWrapper>
  );
};

const NavBarWrapper = styled.div`
  color: var(--link);
  display: flex;
  z-index: 1000;
  animation: 3s ${fadeAnimation};
  ul {
    display: flex;
  }
  li {
    margin: 2.5rem 1vw;
    font-size: 1.1rem;
  }
  a {
    color: inherit;
    transition: 0.7s ease-in-out;
  }

  a:hover {
    color: var(--textWhite);
  }

  @media screen and (max-width: 769px) {
    .nav-links {
      position: absolute;
      top: 0;
      left: 0;
      background: #fff;
      height: 100vh;
      width: 100%;
      flex-direction: column;
      align-items: center;
      clip-path: circle(100px at 100% -20%);
      -web-clip-path: circle(100px at 90% -20%);
      transition: all 1s ease-out;
      z-index: 100;
      .FistLi {
        padding: 3rem 0 0 0;
      }
    }

    .nav-links.open {
      clip-path: circle(1000px at 90% 10%);
      -web-clip-path: circle(1000px at 90% 110%);
      /* justify-content: space-evenly; */
      z-index: 10000;
      .FistLi {
        padding: 3rem 0 0 0;
      }
    }
  }
`;
const MenuBtn = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 10vh;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  padding-right: 1.2rem;
  z-index: 10003;

  .menu-btn__burger {
    width: 20px;
    height: 2px;
    background: var(--link);
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255, 101, 7, 0.2);
    transition: all 0.5s ease-in-out;
  }

  .menu-btn__burger::before,
  .menu-btn__burger::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 2px;
    background: inherit;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255, 101, 7, 0.2);
    transition: all 0.5s ease-in-out;
  }

  .menu-btn__burger::before {
    transform: translateY(-5px);
  }
  .menu-btn__burger::after {
    transform: translateY(5px);
  }

  //ANIMATION

  .menu-btn.open .menu-btn__burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
  }

  .menu-btn.open .menu-btn__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
    background: #00bfff;
  }
  .menu-btn.open .menu-btn__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
    background: #00bfff;
  }
  @media screen and (min-width: 920px) {
    display: none;
  }
`;
export default NavBar;
