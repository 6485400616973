import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FontAwesome from "./FontAwesome";
FontAwesome();

const Footer = () => {
  return (
    <FooterWrapper>
      <div className="adress">
        <Link to="/">
          <h4>{`{ MVDev`} </h4>
          <h6>{`Développement Web }`}</h6>
        </Link>
        <ul>
          <li>Perpignan</li>
          <li>06 49 73 92 93</li>
          <li>contact@mvdev.fr</li>
          <li>
            <Link to="/">www.mvdev.fr</Link>
          </li>
          <li>
            <Link to="/Mentions">
              <p>Mention legales</p>
            </Link>
          </li>
        </ul>
      </div>
      <div className="desc">
        <h4>DÉVELOPPEUR WEB FREELANCE</h4>
        <h6>
          Développeur Web front, back-end, Webdesigner freelance, je suis à
          votre disposition pour répondre à tout type de projets de création de
          sites internet, de développement spécifique ou d'applications web.
        </h6>
      </div>
      <div className="socialWrapper">
        <ul>
          <li>
            <a
              href="https://www.facebook.com/MV-Dev-623769154934120"
              target="_blank"
              rel="noreferrer">
              <FontAwesomeIcon icon={["fab", "facebook-f"]} />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/m.v_dev/"
              target="_blank"
              rel="noreferrer">
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/MVDev6"
              target="_blank"
              rel="noreferrer">
              <FontAwesomeIcon icon={["fab", "twitter"]} />
            </a>
          </li>
        </ul>
      </div>
      <div className="footerMvdev">
        <p>
          2020 © Création <a href="https://www.mvdev.fr">MVDev</a>
        </p>
      </div>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  width: 100%;
  min-height: 250px;
  height: auto;
  color: var(--textWhite);
  padding: 2rem 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .adress {
    width: 33%;

    h6 {
      padding-left: 2.3rem;
      margin-bottom: 1rem;
    }
    a {
      color: inherit;
      transition: 0.7s ease-in-out;
    }
    a:hover {
      color: var(--link);
    }
    ul li {
      font-family: "Lato", serif;
      /* margin-bottom: -0.1rem; */
    }
    @media screen and (max-width: 479px) {
      width: 90%;
    }
  }

  .desc {
    width: 33%;
    h4 {
      font-family: "Roboto Slab", serif;
      text-align: center;
      padding: 0 0 3rem 0;
    }
    h6 {
      font-family: "Lato", serif;
      line-height: 1.3rem;
    }
    @media screen and (max-width: 479px) {
      width: 90%;
    }
  }

  .socialWrapper {
    width: 33%;
    justify-content: center;
    padding: 1rem;

    ul {
      display: flex;
      justify-content: center;
    }

    a {
      color: inherit;
      width: 2.3rem;
      font-size: 1.5rem;
      padding: 0 0.5rem;
      transition: 0.7s ease-in-out;
      cursor: pointer;
    }
    a:hover {
      color: var(--link);
    }
    @media screen and (max-width: 479px) {
      width: 90%;
    }
  }

  .footerMvdev {
    width: 100%;
    margin: 4rem 0 0 0;
    font-family: "Roboto Slab", serif;
    margin: 0 auto;
    p {
      text-align: center;
      margin: 3rem 0 0 0;
      color: #e2e8f0;
    }
    a {
      color: var(--link);
      transition: 0.7s ease-in-out;
    }
    a:hover {
      color: var(--textWhite);
    }
  }
`;

export default Footer;
