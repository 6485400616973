import React from "react";
// import Footer from "./Footer"
import Header from "./Header";

import "./Global.css";
import Footer from "./Footer";
// import { MenuProvider } from "../context/MenuContext"

const Layout = ({ children }) => {
  return (
    <>
      {/* <MenuProvider> */}
      <Header />
      {/* </MenuProvider> */}

      <main style={{ minHeight: "100vh" }}>{children}</main>

      <Footer />
    </>
  );
};

export default Layout;
