import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FontAwesome from "../utils/FontAwesome";

FontAwesome();

const SocialBar = () => {
  return (
    <SocialWrapper>
      <ul>
        <li>
          <a
            href="https://www.facebook.com/MV-Dev-623769154934120"
            target="_blank"
            rel="noreferrer">
            <FontAwesomeIcon icon={["fab", "facebook-f"]} />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/m.v_dev/"
            target="_blank"
            rel="noreferrer">
            <FontAwesomeIcon icon={["fab", "instagram"]} />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/MVDev6" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={["fab", "twitter"]} />
          </a>
        </li>
      </ul>
    </SocialWrapper>
  );
};

const SocialWrapper = styled.div`
  margin: 0;
  ul {
    display: flex;
    z-index: 1001;
    justify-content: flex-end;
    align-items: center;
  }

  a {
    width: 1rem;
    color: var(--link);
    font-size: 1.2rem;
    padding: 0 0.5rem;
    transform: scale(1);
    transition: 0.7s ease-in-out;
    opacity: 0.8;
    cursor: pointer;
    font-family: "Roboto Slab", serif;
    font-weight: bold;
  }
  a:hover {
    opacity: 1;
    color: #e2e8f0;
    transform: scale(1.3) rotate(10deg);
  }
`;

export default SocialBar;
