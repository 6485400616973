import React from "react";
import { Link } from "gatsby";
import styled, { keyframes } from "styled-components";
import NavBar from "./NavBar";
import { headShake } from "react-animations";
const fadeAnimation = keyframes`${headShake}`;

const Header = () => {
  return (
    <HeaderWrapper as="header">
      <div className="title">
        <Link to="/">
          <h2>{`{ MVDev`} </h2>
          <h6>{`Développement Web }`}</h6>
        </Link>
      </div>
      <NavBar />
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  background: inherit;
  color: var(--textWhite);
  min-height: 75px;
  display: flex;

  .title {
    margin: 1% 2.5% 0 2.5%;
    transition: all 1s ease-out;
    font-size: 1.3rem;
    width: 50vw;
    animation: 3s ${fadeAnimation};
    a {
      cursor: pointer;
      color: inherit;
    }
    h2 {
      font-family: "Roboto Slab", serif;
      font-weight: bold;
    }
    h6 {
      margin: -0.3rem 4rem;
      font-family: "Lato", serif;
      font-weight: bold;
    }
  }
`;

export default Header;
